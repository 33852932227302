exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pain-treatments-abdominal-region-pain-tsx": () => import("./../../../src/pages/pain-treatments/abdominal-region-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-abdominal-region-pain-tsx" */),
  "component---src-pages-pain-treatments-acl-tear-tsx": () => import("./../../../src/pages/pain-treatments/acl-tear.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-acl-tear-tsx" */),
  "component---src-pages-pain-treatments-cancer-pain-tsx": () => import("./../../../src/pages/pain-treatments/cancer-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-cancer-pain-tsx" */),
  "component---src-pages-pain-treatments-cervical-spondylosis-tsx": () => import("./../../../src/pages/pain-treatments/cervical-spondylosis.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-cervical-spondylosis-tsx" */),
  "component---src-pages-pain-treatments-disc-prolapse-tsx": () => import("./../../../src/pages/pain-treatments/disc-prolapse.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-disc-prolapse-tsx" */),
  "component---src-pages-pain-treatments-elbow-hand-wrist-pain-tsx": () => import("./../../../src/pages/pain-treatments/elbow-hand-wrist-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-elbow-hand-wrist-pain-tsx" */),
  "component---src-pages-pain-treatments-foot-ankle-pain-tsx": () => import("./../../../src/pages/pain-treatments/foot-ankle-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-foot-ankle-pain-tsx" */),
  "component---src-pages-pain-treatments-herniated-discs-tsx": () => import("./../../../src/pages/pain-treatments/herniated-discs.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-herniated-discs-tsx" */),
  "component---src-pages-pain-treatments-hip-pain-tsx": () => import("./../../../src/pages/pain-treatments/hip-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-hip-pain-tsx" */),
  "component---src-pages-pain-treatments-knee-arthritis-tsx": () => import("./../../../src/pages/pain-treatments/knee-arthritis.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-knee-arthritis-tsx" */),
  "component---src-pages-pain-treatments-knee-pain-tsx": () => import("./../../../src/pages/pain-treatments/knee-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-knee-pain-tsx" */),
  "component---src-pages-pain-treatments-lower-back-pain-tsx": () => import("./../../../src/pages/pain-treatments/lower-back-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-lower-back-pain-tsx" */),
  "component---src-pages-pain-treatments-migraine-headache-tsx": () => import("./../../../src/pages/pain-treatments/migraine-headache.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-migraine-headache-tsx" */),
  "component---src-pages-pain-treatments-myofacial-pain-tsx": () => import("./../../../src/pages/pain-treatments/myofacial-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-myofacial-pain-tsx" */),
  "component---src-pages-pain-treatments-neck-pain-tsx": () => import("./../../../src/pages/pain-treatments/neck-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-neck-pain-tsx" */),
  "component---src-pages-pain-treatments-neuralgias-tsx": () => import("./../../../src/pages/pain-treatments/neuralgias.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-neuralgias-tsx" */),
  "component---src-pages-pain-treatments-neuropathic-pain-tsx": () => import("./../../../src/pages/pain-treatments/neuropathic-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-neuropathic-pain-tsx" */),
  "component---src-pages-pain-treatments-plantar-fasciitis-tsx": () => import("./../../../src/pages/pain-treatments/plantar-fasciitis.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-plantar-fasciitis-tsx" */),
  "component---src-pages-pain-treatments-sciatica-tsx": () => import("./../../../src/pages/pain-treatments/sciatica.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-sciatica-tsx" */),
  "component---src-pages-pain-treatments-shoulder-pain-tsx": () => import("./../../../src/pages/pain-treatments/shoulder-pain.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-shoulder-pain-tsx" */),
  "component---src-pages-pain-treatments-slipped-disc-tsx": () => import("./../../../src/pages/pain-treatments/slipped-disc.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-slipped-disc-tsx" */),
  "component---src-pages-pain-treatments-sports-injuries-tsx": () => import("./../../../src/pages/pain-treatments/sports-injuries.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-sports-injuries-tsx" */),
  "component---src-pages-pain-treatments-trigeminal-neuralgia-tsx": () => import("./../../../src/pages/pain-treatments/trigeminal-neuralgia.tsx" /* webpackChunkName: "component---src-pages-pain-treatments-trigeminal-neuralgia-tsx" */),
  "component---src-pages-pain-tsx": () => import("./../../../src/pages/pain.tsx" /* webpackChunkName: "component---src-pages-pain-tsx" */),
  "component---src-pages-treatments-tsx": () => import("./../../../src/pages/treatments.tsx" /* webpackChunkName: "component---src-pages-treatments-tsx" */)
}

